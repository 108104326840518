module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"SMT Performances","name":"SMT Performances - Pégase Moto","description":"Pégase est le seul Antivol Moto GPS qui chronomètre et analyse vos trajets sur circuit comme en balade - 100% SANS abonnement & 100% Made in France !","icon":"src/assets/svg/favicon.svg","start_url":"/","background_color":"#ffffff","theme_color":"#E71D36","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2a8e6c1a1c9df7e45fa0a45def63dbfc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-WZSD2B7","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer","gtmAuth":"RyKVa6Af9ix3KufDqeOdXg","gtmPreview":"env-1","defaultDataLayer":{"platform":"gatsby"},"routeChangeEvent":"gatsby-route-change"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
